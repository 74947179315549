
/**
 * @name: 统计分析-档口月度结余库存查询
 * @author:
 * @date: 2023-08-04 17:53
 * @description： 统计分析-档口月度结余库存查询
 * @update: 2023-08-04 17:53
 */
import { Vue, Component } from "vue-property-decorator"
import { stallStatisticsQueryStallMonthlySurplusByPageApi, stallStatisticsStallSurplusStockExportApi } from "@/apis/statistics/gate"
import { gateGetGateListApi } from "@/apis/gate/info"
import type { IGate, IGateParam } from "@/apis/statistics/gate/types"
import type { ICrudOption } from "@/types/m-ui-crud"
import type { IGate as Gate } from "@/apis/gate/info/types"
import { deepCopy, exportFile } from "@/utils/common";

@Component({})
export default class statisticsGate extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: IGate[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: IGateParam = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: any = {}
  // crud配置信息
  crudOption: ICrudOption = {
    menu: false,
    column: [
      {
        label: "月份",
        prop: "month",
        search: true,
        hide: true,
        searchSlot: true
      },
      {
        label: "档口名称",
        prop: "gateIdList",
        hide: true,
        search: true,
        searchMultiple: true,
        type: "select",
        dicUrl: "/api/admin/gate/getGateList",
        dicFormatter: (res: any) => ({ list: res.data, label: 'gateName', value: 'id' }),
      },
      {
        label: "档口名称",
        prop: "gateName",
        align: "center"
      },
      {
        label: "商品名称",
        prop: "productName",
        search: true,
        align: "center"
      },
      {
        label: "采购单位",
        prop: "purchaseUnit",
        align: "center",
        width: 120
      },
      {
        label: "成本价1(按采购数计算)",
        prop: "costPrice1",
        align: "center",
        width: 180
      },
      {
        label: "成本价2(按实际入库数计算)",
        prop: "costPrice2",
        align: "center",
        width: 180
      },
      {
        label: "上月结余库存",
        prop: "lastMonthStock",
        align: "center",
        width: 150
      },
      {
        label: "本月实际入库数",
        prop: "checkinQty",
        align: "center",
        width: 150
      },
      {
        label: "本月实际出库数",
        prop: "checkoutQty",
        align: "center",
        width: 150
      },
      {
        label: "本月结余库存",
        prop: "thisMonthStock",
        align: "center",
        width: 150
      },
    ]
  }

  reset () {
    const date = new Date()
    this.queryParam.month = `${date.getFullYear()}-${(date.getMonth()).toString().padStart(2, '0')}`
    this.getList()
  }

  getList () {
    this.tableLoading = true
    const query: IGateParam = deepCopy(this.queryParam)
    if (this.queryParam.gateIdList) {
      // @ts-ignore
      query.gateIdList = this.queryParam.gateIdList.join(',')
    }
    stallStatisticsQueryStallMonthlySurplusByPageApi(query).then(e => {
      this.tableData = e.list
      this.tableTotal = e.total
      this.tableLoading = false
    })
  }

  exportExcel () {
    const query: any = deepCopy(this.queryParam)
    if (this.queryParam.gateIdList) {
      // @ts-ignore
      query.gateIdList = this.queryParam.gateIdList.join(",")
    }
    delete query.page
    delete query.limit
    stallStatisticsStallSurplusStockExportApi(query).then(e => {
      exportFile(e, `档口${this.queryParam.month}月度结余库存.xlsx`)
    })
  }

  created () {
    this.reset()
  }
}
