/**
 * @name: 统计分析-档口月度结余库存查询接口文件
 * @author: itmobai
 * @date: 2023-08-04 17:46
 * @description： 统计分析-档口月度结余库存查询接口文件
 * @update: 2023-08-04 17:46
 */
import {get} from "@/request"
import {IPageRes} from "@/apis/page";
import type { IGate, IGateParam } from "./types";

/**
 * 档口月度库存查询
 * @param data
 * @returns
 */
export const stallStatisticsQueryStallMonthlySurplusByPageApi = (data: IGateParam) => get<IPageRes<IGate[]>>("/admin/stallStatistics/queryStallMonthlySurplusByPage", data)
/**
 * 档口月度库存导出
 * @param param
 * @returns
 */
export const stallStatisticsStallSurplusStockExportApi = (param: any) => get("/admin/stallStatistics/stallSurplusStockExport", param, "blob")
